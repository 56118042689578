<template>
  <v-card id='promotion-list'>
    <v-card-text class='d-flex align-center flex-wrap pb-0'>
      <div class='d-flex align-center pb-5'>
        <v-btn
          color='primary'
          class='me-3'
          :disabled="hasNotPermission('Permissions.PresentDishCreate')"
          :to="{ name: type === 'Appliance' ? 'appliance-dish-add' :   'present-dish-add' }"
        >
          <v-icon
            size='18'
            class='me-1'
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class='d-flex align-center pb-5'>
        <v-text-field
          v-model='filters.searchQuery'
          single-line
          dense
          outlined
          hide-details
          placeholder='Поиск...'
          class='promotion-list-search me-3'
        ></v-text-field>
      </div>
    </v-card-text>
    <v-data-table
      :headers='headers'
      :items='promotions'
      :server-items-length='totalCount'
      :loading='loading'
      :page.sync='filters.pageNumber'
      :items-per-page.sync='filters.pageSize'
      :sort-by.sync='filters.sortBy'
      :sort-desc.sync='filters.sortDesc'
      class='text-no-wrap'
    >
      <template #[`item.name`]='{item}'>
        {{ item.name }}
      </template>
      <template #[`item.actions`]='{item}'>
        <div class='d-flex align-center justify-center'>
          <v-tooltip bottom>
            <template #activator='{ on, attrs }'>
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.PresentDishUpdate')"
                v-bind='attrs'
                :to="{ name: type === 'Appliance' ? 'appliance-dish-edit' : 'present-dish-edit', params: { id: item.id } }"
                v-on='on'
              >
                <v-icon size='18'>
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator='{ on, attrs }'>
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.PresentDishDelete')"
                v-bind='attrs'
                v-on='on'
                @click='deletePopupShow(item.id, item.name)'
              >
                <v-icon size='18'>
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed='deletePromotion' :label='deleteLabel' ref='deletePopup' />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog.vue'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheck,
} from '@mdi/js'
import { hasPermission, toDateSting } from '@/helpers/index'

export default {
  components: { DeleteDialog },
  props: ['type'],
  name: 'PresentDishList',
  data: () => ({
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiCheck,
      mdiEyeOutline,
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('customDishes', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('customDishes', {
      promotions: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Подарочные товары'
    this.fetchPromotions()
  },
  methods: {
    toDate(val) {
      return toDateSting(val)
    },
    hasNotPermission(val) {
      return !hasPermission(val)
    },
    ...mapActions('customDishes', { fetchPromotions: 'fetchList', deletePromotion: 'delete' }),
    deletePopupShow(id, name) {
      this.deleteLabel = `Товар ${name} будет удален.`
      this.$refs.deletePopup.open(id)
    },
  },
}
</script>

<style lang='scss' scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
