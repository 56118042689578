<template>
  <v-card>
    <v-tabs
      v-model='tab'
      show-arrows
      @change='changed'
    >
      <v-tab v-for='(item, index) in customDishTypes' :key='index'>
        <span>{{ item.name }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model='tab'>
      <v-tab-item v-for='(item, index) in customDishTypes' :key='index'>
        <custom-dishes-list :type='item.id'></custom-dishes-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import CustomDishesList from './custom-dish-list.vue'
import { mapFormHandlers } from '@/helpers/forms'

export default {
  name: 'CustomDishTab',
  components: { CustomDishesList },
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters('dictionary', { customDishTypes: 'customDishTypes' }),
    ...mapFormHandlers('customDishes', ['filters.type']),
  },
  methods: {
    ...mapActions('dictionary', ['fetchCustomDishTypes']),
    changed(val) {
      this.filters.type = this.customDishTypes[val].id
    },
  },
  async created() {
    await this.fetchCustomDishTypes()
  },
}
</script>
